header {
    height: 100vh;
    padding-top: 7rem;
    overflow: visible;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    top: -3rem;
}

.firsth5 {
    padding-top: 2rem;
}


/* ---------------CTA--------------- */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ---------------Header Socials--------------- */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0rem;
    bottom: 3rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ---------------ME--------------- */


.me {
    background: linear-gradient(0deg,var(--color-primary), transparent);
    width: 22rem;
    height: 20rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    margin-top: 7rem;
    overflow: hidden;
    padding: 6rem 2rem 3rem 3rem;
    margin-top: 5rem;
}

/* ---------------To Bottom--------------- */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 2rem;
    transform: rotate(0deg);
    font-weight: 300;
    font-size: 0.8rem;
}

/* ---------------Tablet Devices--------------- */

@media screen and (max-width:1024px) {
    header {
        width: 100%;
        
   }
}

/* ---------------Mobile Devices--------------- */

@media screen and (max-width:600px) {
    header{
        height:100vh;
    }

    .header__socials, .scroll__down{
        display: none;
    }
}































